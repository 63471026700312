import React from "react"
import { Link } from "gatsby"

const HomePage = () => {
    return (
        <div>
            <h1>Welcome to Mavic in the SKY...</h1>
            <Link to="/aboutPage/">Go to page 2</Link> <br />
        </div>
    );
};

export default HomePage;